<template>
    <v-layout wrap>
        <v-flex xs12 class="d-flex justify-space-between">
            <span class="display-1 px-5 pb-5 font-weight-medium">Primar</span>
            <v-btn
                v-if="$auth.check() && hasPermission"
                color="secondary"
                @click="add"
                >Editează Primarul</v-btn
            >
        </v-flex>
        <v-flex xs12 ml-5>
            <v-card flat>
                <v-tabs>
                    <v-tab>Informații Generale</v-tab>
                    <v-tab>Atribuțiile Primarului</v-tab>
                    <v-tab-item>
                        <v-card flat class="pa-5">
                            <v-layout align-center flex-md-row flex-column>
                                <v-flex class="flex-grow-0 mr-10">
                                    <img
                                        style="border-radius: 100%"
                                        width="250px"
                                        height="250px"
                                        :src="
                                            data.src
                                                ? data.src
                                                : `${require('@/assets/person_placeholder.png')}`
                                        "
                                        alt=""
                                    />
                                </v-flex>
                                <v-layout class="mt-2" flex-md-row flex-column>
                                    <v-flex class="mr-5 mt-5 mt-md-0">
                                        <p class="ma-0 subtitle-1">Nume</p>
                                        <p class="ma-0 font-weight-bold">
                                            {{ data.name }},
                                            <span class="font-italic">{{
                                                data.title
                                            }}</span>
                                        </p>
                                    </v-flex>
                                    <v-flex
                                        class="mr-5 mt-5 mt-md-0"
                                        v-if="data.party"
                                    >
                                        <p class="ma-0 subtitle-1">Partid</p>
                                        <p class="ma-0 font-weight-bold">
                                            {{ data.party }}
                                        </p>
                                    </v-flex>

                                    <v-flex class="mr-5 mt-5 mt-md-0">
                                        <p class="ma-0 subtitle-1">Telefon</p>
                                        <p class="ma-0 font-weight-bold">
                                            {{ data.phone }}
                                        </p>
                                    </v-flex>
                                    <v-flex class="mr-5 mt-5 mt-md-0">
                                        <p class="ma-0 subtitle-1">Email</p>
                                        <p class="ma-0 font-weight-bold">
                                            {{ data.email }}
                                        </p>
                                    </v-flex>
                                </v-layout>
                            </v-layout>
                            <template v-if="Object.keys(cv).length">
                                <v-divider class="my-10"></v-divider>
                                <v-layout wrap mb-5>
                                    <v-flex
                                        xs12
                                        class="d-flex justify-space-between"
                                    >
                                        <span class="ma-0 headline"
                                            >Curiculum Vitae Primar</span
                                        >
                                    </v-flex>
                                    <v-flex
                                        xs12
                                        v-for="(line, index) in cv"
                                        :key="index"
                                        my-5
                                    >
                                        <v-layout>
                                            <v-flex
                                                xs12
                                                md4
                                                class="font-weight-medium title"
                                                >{{ line.key }}</v-flex
                                            >
                                            <v-flex xs12 md8>{{
                                                line.value
                                            }}</v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                            </template>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card class="pa-5" flat>
                            <v-flex class="ql-editor" v-html="tasks"></v-flex>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import hasPermission from "../mixins/hasPermission";

export default {
    metaInfo: {
        title: "Primar -"
    },
    mixins: [hasPermission],
    name: "Mayor",
    data: () => ({
        data: {},
        cv: [],
        tasks: ""
    }),
    mounted() {
        this.getData();
    },
    methods: {
        add() {
            this.$router.push({
                name: "edit-mayor"
            });
        },
        async getData() {
            const response = await this.axios.get("/mayor");
            this.data = response.data;
            if (this.data.meta) {
                if (this.data.meta.cv) {
                    this.cv = this.data.meta.cv;
                }

                if (this.data.meta.tasks) {
                    this.tasks = this.data.meta.tasks;
                }
            }
        }
    }
};
</script>
