export default {
    async mounted() {
        this.user = this.$auth.user();
    },
    data() {
        return {
            user: null
        };
    },
    methods: {
        can(perm) {
            if (this.user) {
                if (perm) {
                    return this.user.permissions.includes(perm);
                }

                return this.user.permissions.includes("Admin");
            }

            return false;
        }
    },
    computed: {
        hasPermission() {
            if (this.user) {
                if (this.registry && this.registry.category) {
                    return (
                        this.user.permissions.includes("Admin") ||
                        this.user.permissions.includes(
                            this.registry.category.name
                        )
                    );
                }
                return this.user.permissions.includes("Admin");
            }
            return false;
        }
    }
};
